<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-3">
              <select v-model="search_data.coa_master_id" v-validate="{ required: true }" id="coa_master_id" name="coa_master_id" class="form-control form-control-sm">
                <option selected :value="''">Select a coa master</option>
                <option v-for="(coa_master, index) in coa_masters" :key="index" :value="coa_master.id">{{ coa_master.name }}</option>
              </select>
              <div class="invalid-feedback">COA master is required</div>
            </div>
            <div class="col-md-3">
              <select v-model="search_data.download_file_tag" v-validate="{ required: true }" id="download_file_tag" name="download_file_tag" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a download type</option>
                <option value="excel">Excel</option>
              </select>
              <div class="invalid-feedback">Type is required</div>
            </div>
            <div class="col-md-3">
              <select v-model="search_data.month_range" v-validate="{ required: true }" id="month_range" name="month_range" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a month range</option>
                <option value="12">12</option>
                <option value="10">10</option>
                <option value="8">8</option>
                <option value="6">6</option>
                <option value="5">5</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
              </select>
              <div class="invalid-feedback">Month range is required</div>
            </div>
            <div class="col-md-3">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Download</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-2" v-if="hub_arrays.length > 0">
          <div class="col-md-12 float-right">
            <div class="float-right">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <button type="button" class="btn btn-outline-success">
                  <i class="fe fe-more-vertical" /> Download
                </button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-button class="btn btn-outline-success btn-block mr-2" :loading="excelDownloadLoader" @click.prevent="excelDownload"><i class="fa fa-file-excel-o mr-1"></i> Excel</a-button>
                  </a-menu-item>
<!--                  <a-menu-item>-->
<!--                    <a-button class="btn btn-outline-success btn-block mr-2"  @click.prevent="tableToExcel('all_hub_pending_expense_list', 'All Hub Pending Expense')"><i class="fa fa-file-excel-o mr-1"></i> Excel From Table View</a-button>-->
<!--                  </a-menu-item>-->
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" ref="all_hub_pending_expense_list" id="all_hub_pending_expense_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Hub</th>
              <th v-for="(include_last_six_month_array, index) in include_last_six_month_arrays" :key="index">
                {{ include_last_six_month_array['month_name'] }} - {{ include_last_six_month_array['year'] }}
              </th>
              <th>Current Month Expense</th>
              <th>Variance(Last 6 Month)</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(hub_array, index) in hub_arrays" :key="index">
              <td>{{ index + 1}}</td>
              <td>{{ hub_array.name }}</td>
              <td v-for="(requested_transactions, i) in hub_array['requested_transactions']" :key="i">
                {{ requested_transactions['total_requested_amount'] }}
              </td>
              <td>{{ hub_array.last_month_total_requested_amount }}</td>
              <td>
                <span v-if="hub_array.monthly_expense_difference >= 0">
                  <span v-if="hub_array.monthly_expense_difference == 0">
                       {{ hub_array.monthly_expense_difference }}
                  </span>
                  <span class="text-success" v-else >
                    {{ hub_array.monthly_expense_difference }} % &#8593;
                  </span>
                </span>
                <span class="text-danger"  v-else>
                      {{ hub_array.monthly_expense_difference }}% &#8595;
                </span>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="hub_arrays.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      coa_masters: [],
      hub_arrays: [],
      include_last_six_month_arrays: [],
      index: -1,
      excelDownloadLoader: false,
      loading: false,
      btnLoading: false,
      loader: false,
      flag: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        coa_master_id: '',
        download_file_tag: 'excel',
        month_range: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/expense/report/codes')
        .then(response => {
          const data = response.data
          this.coa_masters = data.coa_masters
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true
          this.btnLoading = true
          this.flag = false
          apiClient.post('api/report/all-hub/acc-monthly/pending-transaction-expense/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.include_last_six_month_arrays = response.data.include_last_six_month_arrays
              this.hub_arrays = response.data.hubs
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    excelDownload(index) {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.excelDownloadLoader = true
          apiClient.post('api/report/all-hub/acc-monthly/pending-transaction-expense/download', this.search_data, { responseType: 'blob' }).then(response => {
            this.excelDownloadLoader = false
            if (response.data.size === 68) {
              this.$notification.error({
                message: 'No data found, in this search query',
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
              const link = document.createElement('a')
              link.href = url
              if (this.search_data.download_file_tag === 'pdf') {
                link.setAttribute('download', 'all_hub_month_pending_expense_report.pdf')
              }
              if (this.search_data.download_file_tag === 'excel') {
                link.setAttribute('download', 'all_hub_month_pending_expense_report.xlsx')
              }
              document.body.appendChild(link)
              link.click()
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })
    },
    tableToExcel(table, name) {
      if (!table.nodeType) table = document.getElementById(table)
      var ctx = {
        worksheet: name || 'Worksheet',
        table: table.innerHTML,
      }
      window.location.href = this.uri + this.base64(this.format(this.template, ctx))
    },
    months() {
      return this.$months()
    },
    years() {
      return this.$years()
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
